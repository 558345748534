import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { magic } from "../libs/magic";
import { web3 } from "../libs/provider";
import Web3 from "web3";


export default function Login() {
  const [email, setEmail] = useState("");
  const [account, setAccount] = useState(null);

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const navigate = useNavigate();

  const login = useCallback(async () => {
  //  setIsLoggingIn(true);

    
  await magic.connect.disconnect().catch((e) => {
    console.log(e);
  });
  setAccount(null);
    try {
      
      web3.eth
        .getAccounts()
        .then((accounts) => {
          setAccount(accounts?.[0]);
        })
        .catch((error) => {
          console.log(error);
        });
  
     // await magic.auth.loginWithEmailOTP({ email });
    //  navigate("/");
    } catch {
      setIsLoggingIn(false);
    }
  }, [email]);

  const handleInputOnChange = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  return (
    <div className="container">
      <h1>Please sign up or login</h1>
      <input
        type="email"
        name="email"
        required="required"
        placeholder="Enter your email"
        onChange={handleInputOnChange}
        disabled={isLoggingIn}
      />
      <button onClick={login} disabled={isLoggingIn}>
        Send
      </button>
    </div>
  );
}
