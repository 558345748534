import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Loading from "./Loading";
//import ContractCall from "./ContractCall";
import SendTransaction from "./SendTransaction";
import Info from "./Info";
import { magic,web3 } from "../libs/magic";
import ShowWallet from "./ShowWallet";
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import { provider } from "../libs/provider";
import DiamondLogo from "../../src/logomedium.jpeg"
import { SupportedLocale, SUPPORTED_LOCALES, SwapWidget } from '@uniswap/widgets'
import sendModal from './sendModal'
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';

import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {ethers} from 'ethers'
import axios  from 'axios';

// ↓↓↓ Don't forget to import the widgets' fonts! ↓↓↓
import '@uniswap/widgets/fonts.css'

export default function HomeDD() {


  const currencies = [
    {
      value: 'USDC',
      label: 'USDC',
    }
  ];

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  
  const TOKEN_LIST = [{
    "name": "USD Coin",
    "address": "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    "symbol": "USDC",
    "decimals": 6,
    "chainId": 137,
    "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/polygon/assets/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/logo.png"
  },
//{"chainId":137,"name":"Polygon","address":"0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270","decimals":18,"symbol":"MATIC","logoURI":"https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png"}
]
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEmailError(false)
    setDepositError(false)
    setDepositSuccess(false)
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open2 = Boolean(anchorEl);

  const [nameAddress, setNameAddress] = React.useState("");
  const [nameAmount, setNameAmount] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [depositSuccess, setDepositSuccess] = React.useState(false);
  const [depositError, setDepositError] = React.useState(false);
  const handleChangeAmount = (event) => {
    setNameAmount(event.target.value);
  };

  const handleChangeAddress = (event) => {
    setNameAddress(event.target.value);
  };


  useEffect(() => {
   console.log(nameAddress+"|"+nameAmount)
  }, [nameAddress,nameAmount]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }


    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);



  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  const [account, setAccount] = useState(null);
  const [usdcBalance, setUsdcBalance] = useState(null);
  const [maticBalance, setMaticBalance] = useState(null);
  const [hover, setHover] = useState(false);
  const onHover = () => {
    console.log("sss")
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }


    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [maticBalance]);

  useEffect(() => {
    console.log("Started")
  
    const fetchData = async () => {
      try {
        console.log("Account: "+account)
      
      
      } catch (e){
        console.log(e)
      }
    };
    fetchData();
  },[]);


  useEffect(() => {
    console.log("Started")
    const fetchData = async () => {
      try {
          const result = await getUSDCTokenBalance();
          const maticResult = await getMaticBalance();
          console.log(result)
      } catch (e){
        console.log(e)
      }
    };

    if(account) {
      login();
      fetchData();
    }
   
   
    
  },[account]);
 
  const getWalletInfo = async () => {
    
const walletInfo = await magic.connect.getWalletInfo();
console.log(walletInfo); // { walletType: "magic" | "metamask" | "coinbase_wallet" | "wallet_connect" }

  }
  const sendTransaction = async () => {
    const publicAddress = (await web3.eth.getAccounts())[0];
    const txnParams = {
      from: publicAddress,
      to: publicAddress,
      value: web3.utils.toWei("0.01", "ether"),
      gasPrice: web3.utils.toWei("30", "gwei")
    };
    web3.eth
      .sendTransaction(txnParams)
      .on("transactionHash", (hash) => {
        console.log("the txn hash that was returned to the sdk:", hash);
      })
      .then((receipt) => {
        console.log("the txn receipt that was returned to the sdk:", receipt);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  async function deposit(email,amount) {

    const postData = await fetch(
      "https://cmc-api.onrender.com/api/users/deposit",
      {
          method: "POST",
          headers: {
              "Content-type": "application/json",
          },
          body: JSON.stringify({
              email: "kai.oneal07@gmail.com",
              cashAmount: "1",
          }),
      })

      console.log(postData);
      const jsonData = await postData.json();
      console.log(jsonData);
      console.log(jsonData.result);

      console.log("deposit")
      console.log(postData)
      if(jsonData.result) {
        setDepositSuccess(true)
        setDepositError(false)
      }else {
        setDepositSuccess(false)
        setDepositError(true)
      }
}


  async function checkEmail(email) {


    let res = await axios({
      method: 'get',
      url: 'https://cmc-api.onrender.com/api/users?email='+email,
    // url: 'https://api.dexscreener.com/latest/dex/pairs/polygon/0x0000000000000000000000000000000000001010'
    })
  
return res.data.result
  }
   

  


  const sendTxERC20 = async () => {

    //CHECK EMAIL

    let emailCheck = await checkEmail(nameAddress)
  
if(!emailCheck) {
  setEmailError(true)
return
} else {
  setEmailError(false)
  let tokenAddress = "0x2791bca1f2de4661ed88a30c99a7a9449aa84174";
  let toAddress = "0x660763A04736D9eF6c9128351a6173026039E35B";
  let fromAddress = (await web3.eth.getAccounts())[0];
  // Use BigNumber
  let decimals = web3.utils.toBN(6);
  
  let minABI = [
    // transfer
    {
      "constant": false,
      "inputs": [
        {
          "name": "_to",
          "type": "address"
        },
        {
          "name": "_value",
          "type": "uint256"
        }
      ],
      "name": "transfer",
      "outputs": [
        {
          "name": "",
          "type": "bool"
        }
      ],
      "type": "function"
    }
  ];
  // Get ERC20 Token contract instance
  let contract = new web3.eth.Contract(minABI, tokenAddress);
  // calculate ERC20 token amount
  let value = web3.utils.toWei(nameAmount, "picoether")
  
  try {
    let tx = await contract.methods.transfer(toAddress, value).send({from: fromAddress,gasPrice: web3.utils.toWei("200", "gwei")})
    console.log(tx.transactionHash)
    // CALL API

   let depResult = await deposit(nameAddress,nameAmount)

  console.log(depResult)

  } catch(e) {
  console.log(e)
  }
}
 
// call transfer function


  }
  const sendTx = async () => {

    const publicAddress = (await web3.eth.getAccounts())[0];
    const txnParams = {
      from: publicAddress,
      to: nameAddress,
      value: web3.utils.toWei(nameAmount, "ether"),
      gasPrice: web3.utils.toWei("200", "gwei")
    };
    web3.eth
      .sendTransaction(txnParams)
      .on("transactionHash", (hash) => {
        console.log("the txn hash that was returned to the sdk:", hash);
      })
      .then((receipt) => {
        console.log("the txn receipt that was returned to the sdk:", receipt);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const login = async () => {
    web3.eth
      .getAccounts()
      .then((accounts) => {
        setAccount(accounts?.[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const signMessage = async () => {
    const publicAddress = (await web3.eth.getAccounts())[0];
    const signedMessage = await web3.eth.personal
      .sign("My Message", publicAddress, "")
      .catch((e) => console.log(e));
    console.log(signedMessage);
  };

  
  async function refreshBalances() {
    await getUSDCTokenBalance()
    await getMaticBalance()
  }
  async function getUSDCTokenBalance() {
    const contractABI = require('../abis/usdc_polygon.json');
   
    const minABI = [  
      // balanceOf
      {    
        constant: true,
    
        inputs: [{ name: "_owner", type: "address" }],
    
        name: "balanceOf",
    
        outputs: [{ name: "balance", type: "uint256" }],
    
        type: "function",
      },
    
    ];

    const publicAddress = (await web3.eth.getAccounts())[0];
    const USDContractInstance =  new web3.eth.Contract(minABI, "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174");
    console.log(USDContractInstance)
    let balance = await USDContractInstance.methods.balanceOf(publicAddress).call();
    console.log(balance)
    balance = balance / Math.pow(10, 6);
    setUsdcBalance(balance)
    return balance;
}

async function getMaticBalance() {

  const publicAddress = (await web3.eth.getAccounts())[0];
  const balance = web3.utils.fromWei(
    await web3.eth.getBalance(publicAddress), // Balance is in wei
  );

  
  console.log(balance)

  setMaticBalance(balance)
  return balance;
}

const requestUserInfo = async () => {
  console.log("Clicked")
  try {
    const email = await magic.connect.requestUserInfo({ isResponseRequired: true });
console.log(email)
  } catch(e) {
    console.log("Exception: "+e)
  }
  
  console.log("Opened")
};
const prepaidVisa = async () => {
  console.log("Clicked")
  try {
    window.location.assign('https://checkout.fcfpay.com/prepaid/b93dguhfvykuqw74yzph80j4lsjd1e660tx6zwlfld1mzjfkv1m0bke4oxhw');
  } catch(e) {
    console.log("Exception: "+e)
  }
  
  console.log("Opened")
};

  const showWallet = async () => {
    console.log("Clicked")
    try {
      await magic.connect.showWallet()
    } catch(e) {
      console.log("Exception: "+e)
    }
    
    console.log("Opened")
  };

  const disconnect = async () => {
    await magic.connect.disconnect().catch((e) => {
      console.log(e);
    });
    setAccount(null);
  };

  return (
    <div className="app">
      <div className="container">
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center',color:'white'}}>   <h2 style={{fontSize:40,color:'white'}} >ClubMonteCarlo Wallet</h2></div>
        <div>
   <p>{' '} </p>
   </div>
   <div>
   <p></p>
   </div>
   <div style={{marginTop: '3.5rem',display: 'flex',  justifyContent:'space-around', alignItems:'center'}}>
   <img width={250} height={250} alt='ClubMonteCarlo' style={{borderRadius:125}} src={DiamondLogo} />
   </div>
    <div style={{marginTop: '3.5rem',display: 'flex',  justifyContent:'space-around', alignItems:'center'}}>
   
  
<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>


<Typography id="modal-modal-title" variant="h6" component="h2">
{/* 1) Open FCF Casino:
<br></br>
<a href="https://play.fcfpoker.com/?affiliate=MonteCarlo" target="_blank">https://play.fcfpoker.com/?affiliate=MonteCarlo</a>
<br></br>
2) Go to Cashier
<br></br>
3) Select Deposit Funds */}
{/* <br></br>
<p style={{paddingBottom:20}}>4) Copy address and amount into box below</p> */}
</Typography>
<Typography id="modal-modal-title" variant="h6" component="h2">
      Send USDC
      </Typography>
      
      {emailError ?   
      <Typography style={{color:'red'}} id="modal-modal-title">
      Email Not Found
      </Typography> : null}
    
      
      <Typography id="modal-modal-title" variant="h6" component="h2"> 
<div style={{paddingTop:0}}>
      <TextField
      style={{paddingTop:0}}
          label="Email Address"
          id="filled-start-adornment"
          sx={{ m: 1, width: '25ch' }}
          variant="filled"
          value={nameAddress}
          onChange={handleChangeAddress}
        />
        </div>
      <div style={{display: 'flex',  justifyContent:'space-around', alignItems:'center'}}>
      <TextField
          label="Amount"
          id="filled-start-adornment"
          sx={{ m: 1, width: '25ch' }}
          value={nameAmount}
          onChange={handleChangeAmount}
          variant="filled"
        />
           <TextField
           style={{paddingTop:40}}
          id="outlined-select-currency"
          select
          label=""
          defaultValue="USDC"
          helperText="Please Select USDC"
          >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        </div>
        <div style={{display: 'flex',  justifyContent:'space-around', alignItems:'center'}}>
        <Button style={{fontSize:20,color:'white'}} onClick={sendTxERC20} className="button-row">
          Send
          </Button>
          </div>

    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      Please use only USDC on Polygon Network
    </Typography>

   {depositSuccess ? <Typography style={{color:"blue"}} id="modal-modal-description" sx={{ mt: 2 }}>
      Deposit Success
    </Typography>
    : null}

    {depositError ? <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      Deposit Error
    </Typography>
    : null}

  </Box>
</Modal>
      {!account && (
        <Button style={{fontSize:20,color:'white'}} onClick={login} className="button-row">
          Sign In / Sign Up
        </Button>
      )}

      {account && (
        <>
        <div>
          <Button style={{fontSize:20,color:'white'}} onClick={showWallet}  className="button-row">
  
          Show Wallet/Deposit Funds  {' '}
        
          </Button>
         
      </div>
          <Button  onMouseEnter={onHover}
      onMouseLeave={onLeave}
      role="button"
      tabIndex="-3" style={{fontSize:20,color:'white'}} onClick={prepaidVisa} className="button-row">
          Prepaid Visa  {' '}
          </Button>
          
          {' '} 
          { <Button onClick={handleOpen} className="button-row">
            Send Funds to Decentra Degens
          </Button> }
          {' '}
          {/* <Button onClick={signMessage} className="button-row">
            Sign Message
          </Button>
          {' '}
          <Button onClick={getWalletInfo} className="button-row">
            Get Wallet Info
          </Button>
          {' '}
          <Button onClick={requestUserInfo} className="button-row">
            Get User Info
          </Button>
          {' '} */}
          <Button style={{fontSize:20,color:'white'}} onClick={async () => {console.log(await refreshBalances())}} className="button-row">
          Refresh Balances
          </Button>
          {' '}
          <div>
          <Button style={{fontSize:20,color:'white'}} onClick={disconnect}   onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose} className="button-row">
            Disconnect
          </Button>
          <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open2}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>Disconnect from Wallet</Typography>
      </Popover>
        
          </div>
         
          {' '}
        </>
       
    )}
      </div>
      {/* {hover ? 
            <div className="container" style={{marginTop: '3.5rem',fontSize:25,color:'white'}} >
              <a style={{color:'white'}}>Direct from Crypto to Fiat!!!!
One Time Use
Prepaid Visa cards, from over 20 different Crypto tokens
50.00 minimum up to 750.00
Usable everywhere VISA is accepted!!!</a>
              </div>: 
              null}
        */}
      </div>

      {account && (
        <>
        <div>
        <a className="container">
       
       </a>
       </div>
       <div>
       <p className="container">
       
       </p>
       </div>
       {maticBalance ?
       <div className="container" style={{marginTop: '3.5rem',fontSize:25,color:'white'}} >
       <p><strong>Matic Balance: {maticBalance}</strong></p>
       </div>
        :null}
          {maticBalance ?
       <div className="container" style={{fontSize:25,color:'white'}} >
       <p><strong> USDC Balance: {usdcBalance}</strong></p>
       </div>
           :null}

{maticBalance ?
<div className="Uniswap" style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
    <SwapWidget provider={provider} convenienceFee={Number("100")} convenienceFeeRecipient="0xB4F926817f9aBcdC5cDe497e225a784c0843E8c6" tokenList={TOKEN_LIST}   defaultInputTokenAddress="NATIVE"
      defaultOutputTokenAddress="0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174" />
  </div>
  : null }


      
    </>
    )}

<div style={{display: 'flex', justifyContent:'flex-end',color:'white',paddingRight:30}}>
  Powered By Magic
</div>

    </div>
  );
/*
{
    "name": "USD Coin",
    "address": "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    "symbol": "USDC",
    "decimals": 6,
    "chainId": 137,
    "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/polygon/assets/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/logo.png"
  },
{"chainId":137,"name":"Polygon","address":"0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270","decimals":18,"symbol":"MATIC","logoURI":"https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png"}
*/
      }

