import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";


// Views
import Login from "./Login";
import Home from "./Home";
import HomeDD from "./HomeDD";

export default function App() {

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  return (
    <BrowserRouter>
      <div className="App" style={{backgroundColor:'rgb(27, 25, 24)', minHeight:windowSize.innerHeight}}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/dd" element={<HomeDD />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}
